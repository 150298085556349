<template>
<div id="page-branch-list">
    <heat-map v-show="heatmapList && heatmapList.length == 0"/>
    <heatmap-information v-show="heatmapList && heatmapList.length > 0" />
</div>
</template>

<script>
import HeatMap from './HeatmapService.vue';
import HeatmapInformation from './HeatmapInformation.vue'
export default {
    components:{
        HeatMap,
        HeatmapInformation
    },
    data(){
        return {
            heatmapList: null
        }
    },
    methods: {
        getListHeatmap: function(){
            let userInfo = JSON.parse(localStorage.getItem('userInfo'));
            const payload = {
                organizationBranchId: userInfo.organizationBranchId ? userInfo.organizationBranchId : null
            }
            this.$crm.post('/heatmap/find-by-condition', payload).then((res) => {
                this.heatmapList = res.data;
            }).catch((err) => {
                return this.$vs.notify({
                    text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            })
        },
    },
    created(){
        this.getListHeatmap();
    }
}
</script>
